<template>
    <!-- <b-modal no-close-on-backdrop no-close-on-esc ... -->
    <b-modal id="modal_advanced_search" size="xl"  :hide-header="true" hide-footer>
        <div class="close-button float-right"><button size="lg" @click="close()" title="Fechar janela">
              <i class="text-danger fa fa-times fa-lg"></i>
            </button>
        </div>
        <div v-if="loading">
            <b-spinner class="spinner" variant="success" label="Carregando"></b-spinner> <i>Carregando Filtros</i>
        </div>



        <div v-if="loading==false" class="card h-100 py-1">
            <p class="d-flex t-information">Selecione 1 ou + filtros</p>
            <div class="filters d-flex align-items-center justify-content-center flex-wrap" >
                <ButtonFilter name="Fichas publicadas" id="publicadas" value="S" title="Filtro por espécies com fichas publicadas" />
                <Button name="Nível taxonômico" id="taxonId" title="Filtro por espécies de níveis taxonômico" />
                <Button name="Categoria" id="categoriaIds" title="Filtro de espécies por categoria" />
                <Button name="Bioma" id="biomaIds" title="Filtro de espécies por biomas" />
                <Button name="Unidade de conservação" id="ucIds" title="Filtro de espécies por unidade de conservação" />
                <Button name="Uso" id="usoIds" title="Filtro de espécies por uso" />
                <Button name="Região" id="regiaoIds" title="Filtro de espécies por região" />
                <Button name="Estado" id="estadoIds" title="Filtro de espécies por estado" />
                <Button name="Grupo" id="grupoIds" title="Filtro de espécies por grupo" />
                <Button name="Ameaça" id="ameacaIds" title="Filtro de espécies por tipo de ameça" />
                <Button name="Bacia hidrográfica" id="baciaIds" title="Filtro de espécies por bacia hidrográfica" />
                <ButtonFilter name="Endêmica do Brasil" id="endemicaBrasil" value="s" title="Filtro por espécies endêmicas do Brasil" />
                <ButtonFilter name="Consta em lista nacional oficial" id="presencaListaVigente" value="s"
                    title="Filtro por espécies ou subespécies que são oficialmente ameaçadas de extinção por constarem nas Lista Nacionais Oficiais de Espécies Ameaçadas de Extinção" />
                <Button name="Autor" id="autorNome" title="Filtro de espécies por autoria da ficha" />
            </div>
            <SearchBar :advanced="true"/>
            <div class="formFilter">
                    <FilterTaxonomicGroup>
                        <template v-slot:filter="props">
                            <div class="row inputClass pr-md-4">
                                <div class="col-11 col-md">
                                    <SelectOptions name="Nível Taxonômico" id="idLevel" :data="selectOptionsData['taxonGroups']" :multiple="false" />
                                </div>
                                <div class="col-11 my-4 my-md-0 col-md">
                                    <SelectOptions name="Condição" id="criteria" :data="[{id: 'startWith', name: 'Iniciado por'}, {id: 'containWord', name: 'Contém termo'}]" :multiple="false" />
                                </div>
                                <div class="col-11 col-md">
                                    <SelectOptionsAjax name="Taxonomia" id="taxonId" :endpoint="props.endpoint" />
                                </div>
                            </div>
                        </template>
                    </FilterTaxonomicGroup>
                    <FilterPexGroup id="categoriaIds">
                        <template v-slot:filter="props">
                            <SelectOptions name="Categoria" id="categoriaIds" :data="selectOptionsData['categories']" ref="categoriaIds" />
                            <SwitchButton name="Possivelmente Extinta (PEX)?" id="pex" filterId="categoriaIds" ref="pex" v-if="props.showFilter" />
                        </template>
                    </FilterPexGroup>
                    <FilterEndemicGroup id="biomaIds" idEndemic="endemicaBioma" idHistoric="presencaAtualBioma">
                        <template v-slot:filter>
                            <SelectOptions name="Bioma" id="biomaIds" :data="selectOptionsData['biomas']" ref="biomaIds" />
                            <SwitchButton name="Endêmica" id="endemicaBioma" filterId="biomaIds" ref="endemicaBioma" title="No Brasil, a espécie é exclusiva do(s) bioma(s) selecionado(s)"/>
                            <SwitchButton name="Histórico" id="presencaAtualBioma" filterId="biomaIds" ref="presencaAtualBioma" title="Incluir registros históricos (local onde a espécie, sabidamente, não ocorre mais)" />

                        </template>
                    </FilterEndemicGroup>
                    <FilterUC name="Unidade de conservação" id="ucIds">
                        <template v-slot:morefilters="props">
                            <div class="label-form">
                                <div class="d-flex">
                                    <div class="infoTip"><img src="/img/info.png" alt="" title="Informe a unidade de conservação e clique no botão [Buscar]"></div>
                                    <p class="label">Unidade de conservação</p>
                                </div>
                                <div class="radio-buttons d-flex">
                                    <!-- Group of default radios - option 1 -->
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" id="defaultGroupExample1" name="groupOfDefaultRadios" value="F" @input="props.getCheckedValue" :checked="props.persistedValue == 'F' ? 'checked' : ''">
                                        <label class="custom-control-label" for="defaultGroupExample1">Federal</label>
                                    </div>

                                    <!-- Group of default radios - option 2 -->
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" id="defaultGroupExample2" name="groupOfDefaultRadios" value="E" @input="props.getCheckedValue" :checked="props.persistedValue == 'E' ? 'checked' : ''">
                                        <label class="custom-control-label" for="defaultGroupExample2">Estadual</label>
                                    </div>

                                    <!-- Group of default radios - option 3 -->
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" id="defaultGroupExample3" name="groupOfDefaultRadios" value="R" @input="props.getCheckedValue" :checked="props.persistedValue == 'R' ? 'checked' : ''">
                                        <label class="custom-control-label" for="defaultGroupExample3">RPPNs</label>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:filter="props">
                            <SelectOptionsAjaxMultiple name="Unidade de conservação" id="ucIds" :endpoint="props.endpoint" ref="ucIds" />
                        </template>
                    </FilterUC>
                    <FilterComp id="usoIds">
                        <template v-slot:filter>
                            <!-- OLD Select Options Ajax Multiple
                            <SelectOptionsAjaxMultiple name="Uso" id="usoIds" endpoint="/searchUso?" />
                            -->
                          <SelectOptions name="Uso" id="usoIds" :data="selectOptionsData['uses']" />
                        </template>
                    </FilterComp>
                    <FilterEndemicGroup id="regiaoIds" idEndemic="endemicaRegiao" idHistoric="presencaAtualRegiao">
                        <template v-slot:filter>
                            <SelectOptions name="Região" id="regiaoIds" :data="selectOptionsData['regions']" ref="regiaoIds" />
                            <SwitchButton id="endemicaRegiao" filterId="regiaoIds" name="Endêmica" ref="endemicaRegiao" title="No Brasil, a espécie é exclusiva da(s) regiõe(s) selecionado(s)"/>
                            <SwitchButton name="Histórico" id="presencaAtualRegiao" filterId="estadoIds" ref="presencaAtualRegiao" title="Incluir registros históricos (local onde a espécie, sabidamente, não ocorre mais)" />
                        </template>
                    </FilterEndemicGroup>
                    <FilterEndemicGroup id="estadoIds" idEndemic="endemicaEstado" idHistoric="presencaAtualEstado">
                        <template v-slot:filter>
                            <SelectOptions name="Estado" id="estadoIds" :data="selectOptionsData['states']" ref="estadoIds" />
                            <SwitchButton id="endemicaEstado" filterId="estadoIds" name="Endêmica" ref="endemicaEstado" title="No Brasil, a espécie é exclusiva do(s) estados(s) selecionado(s)."  />
                            <SwitchButton name="Histórico" id="presencaAtualEstado" filterId="estadoIds" ref="presencaAtualEstado" title="Incluir registros históricos (local onde a espécie, sabidamente, não ocorre mais)" />
                        </template>
                    </FilterEndemicGroup>
                    <FilterComp id="grupoIds">
                        <template v-slot:filter>
                            <SelectOptions name="Grupo" id="grupoIds" :data="selectOptionsData['groups']" />
                        </template>
                    </FilterComp>
                    <FilterComp id="ameacaIds">
                        <template v-slot:filter>
                            <!-- OLD Select Options Ajax Multiple
                            <SelectOptionsAjaxMultiple name="Ameaça" id="ameacaIds" endpoint="/searchAmeaca?" />
                            -->
                          <SelectOptions name="Ameaça" id="ameacaIds" :data="selectOptionsData['threats']" />
                        </template>
                    </FilterComp>

                    <FilterEndemicGroup id="baciaIds" idEndemic="endemicaBacia" idHistoric="presencaAtualBacia">
                        <!--<FilterComp id="baciaIds">-->
                        <template v-slot:filter>
                            <!-- OLD Select Options Ajax Multiple
                            <SelectOptionsAjaxMultiple name="Bacia hidrográfica" id="baciaIds" endpoint="/searchBacia?" />
                            -->
                            <SelectOptions name="Bacia hidrográfica" id="baciaIds" :data="selectOptionsData['hydrographicBasin']" ref="baciaIds" />
                            <SwitchButton id="endemicaBacia" filterId="baciaIds" name="Endêmica" ref="endemicaBacia" title="No Brasil, a espécie é exclusiva da(s) Bacia(s) hidrográfica(s) selecionado(s)"  />
                            <SwitchButton name="Histórico" id="presencaAtualBacia" filterId="baciaIds" ref="presencaAtualBacia" title="ncluir registros históricos (local onde a espécie, sabidamente, não ocorre mais)" />
                        </template>
                        <!--</FilterComp>-->
                    </FilterEndemicGroup>

                    <FilterComp id="autorNome">
                        <template v-slot:filter>
                            <TextSearch name="Autor" id="autorNome" placeholder="Informe o nome do autor da ficha" />
                        </template>
                    </FilterComp>
            </div>
            <div class="filters d-flex flex-row-reverse flex-wrap">
                <ButtonLimparFiltros />
                <ButtonSearch />
            </div>

        </div>
    </b-modal>
</template>

<script>
import ButtonLimparFiltros from '@/components/searchComponents/ButtonLimparFiltros.vue'
import ButtonSearch from '@/components/searchComponents/ButtonSearch.vue'
import Button from '@/components/searchComponents/filterButton.vue'
import ButtonFilter from '@/components/searchComponents/ButtonFilter.vue'
import FilterUC from '@/components/searchComponents/FilterUC.vue'
import FilterEndemicGroup from '@/components/searchComponents/FilterEndemicGroup.vue'
import FilterPexGroup from '@/components/searchComponents/FilterPexGroup.vue'
import FilterTaxonomicGroup from '@/components/searchComponents/FilterTaxonomicGroup.vue'
import SelectOptionsAjaxMultiple from '@/components/searchComponents/SelectOptionsAjaxMultiple.vue'
import SelectOptionsAjax from '@/components/searchComponents/SelectOptionsAjax.vue'
import SelectOptions from '@/components/searchComponents/SelectOptions.vue'
import SearchBar from '@/components/searchComponents/SearchBar.vue'
import FilterComp from '@/components/searchComponents/FilterComponent.vue'
import SwitchButton from '@/components/searchComponents/SwitchButton.vue'
import barramento from '@/barramento'
import selectOptionsFilters from '@/selectOptionsFilters_mixins.js'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
import TextSearch from '@/components/searchComponents/TextSearch.vue'

export default {
    components: {
    Button,
    ButtonLimparFiltros,
    ButtonFilter,
    SearchBar,
    SelectOptionsAjaxMultiple,
    SelectOptionsAjax,
    FilterComp,
    SwitchButton,
    SelectOptions,
    FilterUC,
    FilterTaxonomicGroup,
    FilterEndemicGroup,
    FilterPexGroup,
    ButtonSearch,
    TextSearch
},
    mixins:[selectOptionsFilters,selectOptionsStorage],
    data() {
        return {
            filter: [],
            items: [],
            queryResults: {},
            queryResultsString: "",
            filtersValues: {},
            loading: true,
            selectOptionsData:[]
        }
    },
    watch: {
        filter(){
            var arr = {}

            for (var item in this.filtersValues){
                if(this.filter.includes(item)){
                    arr[item] = this.filtersValues[item]
                }
            }
            this.filtersValues = arr
        }
    },
    created() {
        this.getOptions();
        barramento.$on('selectOptionsLoaded', (data) => {
            this.selectOptionsData = data
            this.loading = false
        })
        barramento.$on('openModal', () => {
            if (this.checkIfTagExists('filterFromGraphic','true')) {
                this.removeAlTagsFromKey('filterFromGraphic')
            }
            this.$bvModal.hide('modal_search_result')
            this.$bvModal.show('modal_advanced_search')
        })

    },
    mounted(){
        this.$bvModal.show('modal_advanced_search')
    },
    methods: {
        close(){
            this.$bvModal.hide('modal_advanced_search')
        }
    }
}
</script>

<style lang="scss" scoped>
    @media only screen and (min-width: 769px) {
        #modal_advanced_search .card .search_bar {
            margin-left: 90px;
        }
        #modal_advanced_search .t-information {
            margin: auto;
            margin-top: 70px;
            color: #282828;
        }
        #modal_advanced_search .modal-dialog .modal-content {
            border-radius: 15px !important;
        }
        #modal_advanced_search .modal-content {
            max-width: 850px !important;
            margin: auto;
        }
        #modal_advanced_search .close-button {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1000;
            margin-top: 15px;
            margin-right: 15px;

        }
        #modal_advanced_search .close-button button {
            background-color: transparent;
            border: none;
        }
        #modal_advanced_search .label {
            font-weight: bold;
            color: #000;
            margin-left: 15px;
            margin-bottom: -40px;
        }
        #modal_advanced_search .label-form {
            margin-top: 35px;
        }
        #modal_advanced_search .custom-radio {
            margin-left: 15px;
            padding-top: 25px;
        }
        #modal_advanced_search .custom-control-label {
            font-weight: bold;
            color: #000;
            font-size: 12px;
            padding-top: 5px;
        }
        #modal_advanced_search .radio-buttons {
            margin-left: 35px;
            margin-bottom: -25px;
        }
        #modal_advanced_search .filters {
            margin-right: 60px;
            margin-left: 60px;
            margin-bottom: 60px;
            margin-top: -20px;
        }
        #modal_advanced_search .formFilter {
            width: 700px;
            margin:auto;
            margin-top: 80px;
            margin-bottom: 80px;

        }

        .icon-info {
          cursor: pointer;
          margin-bottom: 20px;
          padding: 5px 10px 5px 10px;
          background: #E2F5A1 !important;
          border-radius: 20px;
          font-size: 10px;
          margin-top:30px;
        }
    }
    @media only screen and (max-width: 768px) {
        .card .search_bar {
            margin-left: 90px;
        }
        .t-information {
            margin: auto;
            margin-top: 70px;
            color: #282828;
        }
        .modal-dialog .modal-content {
            border-radius: 15px !important;
        }
        .modal-content {
            max-width: 850px !important;
            margin: auto;
        }
        .close-button {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1000;
            margin-top: 15px;
            margin-right: 15px;
        }
        .close-button button {
            background-color: transparent;
            border: none;
        }
        .label {
            font-weight: bold;
            color: #000;
            margin-left: 15px;
            margin-bottom: -40px;
        }
        .label-form {
            margin-top: 35px;
        }
        .custom-radio {
            margin-left: 15px;
            padding-top: 25px;
        }
        .custom-control-label {
            font-weight: bold;
            color: #000;
            font-size: 12px;
            padding-top: 5px;
        }
        .radio-buttons {
            margin-left: 35px;
            margin-bottom: -25px;
        }
        // .filters {
        //     margin-right: 60px;
        //     margin-left: 60px;
        //     margin-bottom: 60px;
        //     margin-top: -20px;
        // }
        .formFilter {
            font-size:0.8rem;
            width: 100%;
            margin:auto;
            margin-top: 80px;
            margin-bottom: 80px;

        }
        .icon-info {
          cursor: pointer;
          margin-bottom: 20px;
          padding: 5px 10px 5px 10px;
          background: #E2F5A1 !important;
          border-radius: 20px;
          font-size: 10px;
          margin-top:30px;
        }
    }
</style>
