<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>
    <div v-else>
        <div class="info t-14 grey-text"  v-if="items.description != ''"><span v-html=items.description></span></div>
        <div class="info t-14 grey-text"  v-else><b>Não foram encontradas informações para o táxon</b></div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    components:{
        LoadingAjax
    },
    props: ['fichaId'],
    data(){
        return {
            items: [],
            loading: true
        }
    },
    created(){
       
        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-uso" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=uses'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    }
}
</script>

<style>

</style>