<template>
    <div>
      <loading-ajax v-if="loading"></loading-ajax>
      <div v-else>
            <div v-if="items.evaluators">
                <p class="bold">Avaliadores:</p>
                <div v-html="items.evaluators"></div>
            </div>
            <br>
            <div v-if="items.validators">
                <p class="bold">Validadores:</p>
                <div v-html="items.validators"></div>
            </div>
      </div>
    </div>
  </template>

  <script>
  import api from '@/services/api.js'
  import LoadingAjax from '@/components/globalComponents/Loading.vue'

  export default {
      components:{
          LoadingAjax
      },
      props: ['fichaId','color'],
      data(){
          return {
              items: [],
              loading: true
          }
      },
      created(){

          this.$root.$on(
          "bv::collapse::state",
          function (collapseId, isJustShown) {
              if (collapseId == "collapse-avaliadores" && isJustShown) {
                  var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=evaluatorsAndValidators'
                  api.get(apiUrl).then(response => (
                      this.items.evaluators = response.data.data.evaluators[0]
                      ,this.items.validators = response.data.data.validators[0]
                  )).finally(() => this.loading = false)
              }
          }.bind(this)
          );
      }
  }
  </script>

  <style>
    .bold{
        font-weight: 700;
    }
  </style>