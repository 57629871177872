<template>
    <FilterComp :info="false" id="ucIds">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data" :getCheckedValue="getCheckedValue" :selectedValue="selectedValue" :endpoint="endpointWithFilter" :persistedValue="persistedValue">
            </slot>
        </template>
    </FilterComp>
</template>
<script>
import FilterComp from '@/components/searchComponents/FilterComponent.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
export default {
    components:{FilterComp},
    mixins:[selectOptionsStorage],
    props:['id'],
    data() {
        return {
            selectedValue: "F",
            endpoint: '/searchUc?sphere=',
            endpointWithFilter: '/searchUc?sphere=F',
            filterName:'esferaUc',
            filter: {'esferaUc': 'F'},
            persistedValue: "F"
        }
    },
    created() {
        let extraFilterValue = this.getExtraFilters(this.id,this.filterName)
        if ( extraFilterValue != false) {
             this.persistedValue = extraFilterValue;
        }
        this.$on('selectOptionsAjaxMultipleAddTag', ()=> {
            this.setFilter()
        })
        this.$on('selectOptionsAjaxMultipleRemoveTag', ()=> {
            this.setFilter()
        })
    },
    methods:{
        getCheckedValue(e) {
            this.selectedValue = e.currentTarget.value
            this.endpointWithFilter = this.endpoint + this.selectedValue
            this.removeAlTagsFromKey('ucIds')
            this.$nextTick(function() { // lazy
                if (this.$scopedSlots.filter()[0].context.$refs[this.id] != undefined ) {
                    this.$scopedSlots.filter()[0].context.$refs[this.id].$children[0].selected = 0
                }

                this.filter[this.filterName] = new Array()
                this.filter[this.filterName] = this.selectedValue
            })
        },
        setFilter() {
            this.$nextTick(function() { // lazy
                if (this.$scopedSlots.filter()[0].context.$refs[this.id] != undefined ) {
                    var selectOptions = this.$scopedSlots.filter()[0].context.$refs[this.id].$children[0].selected
                    var selectOptionsLength = 0
                    if (selectOptions != undefined) {
                        selectOptionsLength = selectOptions.length
                    }
                    if (selectOptionsLength > 0) {
                        var checkFilter = this.checkIfTagExists(this.id,'extraFilters') 
                        if (checkFilter == true) {
                            this.removeTag(this.id,'extraFilters')
                        }
                        this.addTag(this.id,{'extraFilters': this.filter})  
                    } else {
                        if (this.checkIfTagExists(this.id,'extraFilters')) {
                            this.removeAlTagsFromKey(this.id)
                        }
                    }
                }
            })
        }
    }
}
</script>
