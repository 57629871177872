<template>
  <div class="col-12 my-2 px-0 tokenfield">
    <v-select
      label="name"
      v-model="selected"
      :filterable="false"
      :options="options"
      :placeholder="name"
      @search="onSearch"
      @option:selecting="tagAdded"
      @input="tagRemoved"
    >
      <template #option="{ name, path, tree }">
        <span :title="path" v-if="path != undefined">{{name}}</span>
        <span :title="tree" v-if="tree != undefined">{{name}}</span>
        <span v-else>{{name}}</span>
      </template>
      <template #no-options="{ search, loading }">
        <template v-if="!loading && countCharForSearch!=0">
            <em style="opacity: 0.5">Digite {{countCharForSearch}} ou mais caracteres.</em>
        </template>
        <template v-if="!loading && countCharForSearch==0">
          <em style="opacity: 0.5">
              Nenhum resultado para <em>{{ search }}</em>.
          </em>
        </template>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import api from "@/services/api.js";
import { debounce } from "lodash";
export default {
  props: ["name", "id", "endpointUrl", "selectedTagsData"],
  components: {
    vSelect,
  },
  data() {
    return {
      countCharForSearch: 3,
      options: [],
      selected: '',
    };
  },
  created() {
    this.selected = this.selectedTagsData
  },
  methods: {
    onSearch(search, loading) {
      if (search.length >= 3) {
        this.countCharForSearch = 0
        loading(true);
        this.search(loading, search, this);
      } else {
        loading(false);
        this.options = []
        this.countCharForSearch = 3 - search.length
      }
    },
    tagAdded (tag) {
        this.$parent.$emit('vueSelectTagAdded',tag)
    },
    tagRemoved(tag) {
      if (tag == null) {
         this.$parent.$emit('vueSelectTagRemoved',this.id)
      }
    },
    search: debounce((loading, search, vm) => {
      vm.getOptions(vm.endpointUrl + "&q=" + search).finally(function () {
        loading(false);
      });
    }, 1000),
    async getOptions(url) {
      await api
        .get(url)
        .then((response) => (this.options = response.data.data));
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.vs__dropdown-option--selected {
  display: none;
}

.tags-input-wrapper-default.active {
  border: none;
  box-shadow: none;
  outline: 0 none;
}

.typeahead-dropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.typeahead-dropdown li {
  padding: 0.25em 1em;
  cursor: pointer;
}

/* Typeahead elements style/theme */
.tags-input-typeahead-item-default {
  color: #000000;
  background-color: #bee45b;
}

.tags-input-typeahead-item-highlighted-default {
  color: #000000;
  background-color: #e2f5a1 !important;
}

.tokenfield input {
  width: 100%;
  padding-left: 7px !important;
  padding-right: 0 !important;
  border: none;
  background: transparent;
}
.tokenfield .tags-input-badge-selected-default {
  background: transparent !important;
  border: 1px solid black !important;
}
.tokenfield .tags-input-wrapper-default {
  border: none;
  border-bottom: 2px solid black !important;
  border-radius: 0;
}
.tokenfield .col-12 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.tokenfield .tags-input-badge .span-tag {
  min-width: 50px;
  border: 2px solid black;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
}
.tokenfield .tags-input-badge .tags-input-remove {
  color: black;
  padding-left: 15px;
  padding-right: 0px;
  font-size: 12px;
  font-weight: bold;
}
.tokenfield .tags-input-badge {
  margin-right: 10px;
  left: 0;
}
.tokenfield .tags-input-wrapper-default {
  left: 0;
  margin-top: 18px;
}
.tokenfield .typeahead-dropdown {
  background-color: #bee45b;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tokenfield .typeahead-dropdown li {
  color: black;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
}
.tokenfield {
  padding: 0;
}
</style>
