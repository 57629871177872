<template>
    <FilterComp info="false" :id="id">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data">
            </slot>
        </template>
    </FilterComp>
</template>
<script>
import FilterComp from '@/components/searchComponents/FilterComponent.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
    props:['id','idEndemic','idHistoric'],
    components:{FilterComp},
    mixins:[selectOptionsStorage],
    created() {
        this.setEndemicFilter()
        this.$on('selectOptionsAddTag', ()=> {
            this.setEndemicFilter()
        })
        this.$on('selectOptionsInputTag', ()=> {
            this.setEndemicFilter()
        })
        this.$on('switchButtonToggle', () =>  {
            this.setEndemicFilter()
        })
    },
    methods:{
        setEndemicFilter() {
            this.$nextTick(function() { // lazy
                //console.log('Valor:')
                //console.log(this.$scopedSlots.filter()[0].context.$refs[this.id])
                if (this.$scopedSlots.filter()[0].context.$refs[this.id] != undefined ) {
                    var selectOptions = this.$scopedSlots.filter()[0].context.$refs[this.id].$children[0].selected
                    var selectOptionsLength = 0
                    if (selectOptions != undefined) {
                        selectOptionsLength = selectOptions.length
                    }
                    // Endemicas
                    var switchButtonValue = this.$scopedSlots.filter()[0].context.$refs[this.idEndemic]
                    // Registros historicos / presenca Atual em Bioma, Estados e Bacias
                    var switchButtonHistoricValue = this.$scopedSlots.filter()[0].context.$refs[this.idHistoric]

                    // Endemicas
                    if (switchButtonValue != undefined) {
                        switchButtonValue = this.$scopedSlots.filter()[0].context.$refs[this.idEndemic].value
                    }
                    // Registros historicos / presenca Atual em Bioma, Estados e Bacias
                    if (switchButtonHistoricValue != undefined) {
                        switchButtonHistoricValue = this.$scopedSlots.filter()[0].context.$refs[this.idHistoric].value
                    }

                    //IF there is at least one item selected in the filter, then set endemic filter
                    if (selectOptionsLength > 0) {
                        var checkFilter = this.checkIfTagExists(this.id,'extraFilters')
                        if (checkFilter == true) {
                            this.removeTag(this.id,'extraFilters')
                        }

                        var edmicFilter = {}
                        edmicFilter[this.idEndemic] = new Array()

                        // Endemicas
                        if (switchButtonValue) {
                            edmicFilter[this.idEndemic] = 'S'
                        } else {
                            edmicFilter[this.idEndemic] = 'N'
                        }
                        // Registros historicos / presenca Atual em Bioma, Estado e Bacia
                        if (switchButtonHistoricValue) {
                            edmicFilter[this.idHistoric] = 'S'
                        } else {
                            edmicFilter[this.idHistoric] = 'N'
                        }

                        this.addTag(this.id,{'extraFilters': edmicFilter})
                    }
                }
            });
        }
    }
}
</script>
