<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>
    <div v-else>
        <div class="info t-14 grey-text" v-for="item in items.tree" :key="item.level"><b v-html="item.name"></b><b>:</b> <span v-html="item.value"></span></div>
        <div class="info t-14 grey-text"  v-if="items.commonNames != ''"><b>Nomes comuns:</b> <span v-html="items.commonNames"></span></div>
        <div class="info t-14 grey-text" v-if="items.oldNames != ''"><b>Nomes antigos:</b> <span v-html="items.oldNames"></span></div>
        <div class="info t-14 grey-text"  v-if="items.taxonomicNotes != ''"><br><b>Notas Taxonômicas e morfológicas:</b> <span v-html="items.taxonomicNotes"></span></div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    components:{
        LoadingAjax
    },
    props: ['fichaId'],
    data(){
        return {
            items: [],
            loading: true
        }
    },
    created(){

        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-classTax" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=taxonomicClassification'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    }
}
</script>

<style>

</style>
