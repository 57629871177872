<template>
    <div class="inputClass">
        <TextInput :name="name" :id="id" :value="value" :placeholder="placeholder" />
    </div>
</template> 

<script>
import TextInput from '@/components/searchComponents/TextInput.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
    props: {
      name: String, 
      id: String,
      placeholder: String
    },
    components: {
        TextInput
    },
    mixins:[selectOptionsStorage],
    data() {
        return {
            value: "",
        }
    },
    created() {        

    }
}
</script>

<style lang="scss">

     @import '@/web/scss/form.scss';

    .inputClass {
        margin: 15px;
        width: 100%;
    }
</style>