<template>
    <div>
        <b-button :class="'button-'+color" class="button-section fullWidth button-collapse   d-flex align-items-center justify-content-between" v-b-toggle="'collapse-'+id" style="width: 100%">
            <div>{{name}}</div>
            <div class="plus-button">
                <span class="plus-bar plus-bar--1"></span>
                <span class="plus-bar plus-bar--2"></span>
            </div>
        </b-button>
        <b-collapse :id="'collapse-'+id" class="p-3">
            <b-card><slot></slot></b-card>
        </b-collapse>
    </div>
</template>

<script>
export default {
    props: ['name', 'color', 'id'],

}
</script>

<style lang="scss">
    @import '@/web/scss/ficha.scss';
</style>