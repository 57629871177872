<template>
    <div class="inputClass">
        <vue-select :name="name" :id="id" :endpointUrl="endpoint" :selectedTagsData="selectedTags"/>
    </div>
</template>

<script>
import vueSelect from '@/components/searchComponents/VueSelectMultiple.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
    props: ['id', 'name', 'info', 'type', 'endpoint'],
    components: {
        vueSelect
    },
    mixins:[selectOptionsStorage],
    data() {
        return {
            valueInput: '',
            select: {},
            selectedTags: []
        }
    },
    created() {
        var t = this
        this.$on('tagAdded',(tag) => {
            t.addTag(this.id,tag)
            this.$parent.$parent.$emit('selectOptionsAjaxMultipleAddTag')
        } ) 
        this.$on('tagRemoved',(tag) => {
            t.removeTag(this.id,tag)
            this.$parent.$parent.$emit('selectOptionsAjaxMultipleRemoveTag')
        }) 
        var selectedTags = this.getDataWithoutExtraFilters(this.id)
        if (selectedTags != false) {
            this.selectedTags = selectedTags
        } else {
            this.selectedTags = ''
        }
    }
}
</script>

<style lang="scss">

     @import '@/web/scss/form.scss';

    .inputClass {
        margin: 15px;
        width: 100%;
    }
</style>