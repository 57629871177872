<template>
    <div class="inputClass">
        <SelectInput :name="name" :id="id"  :data="data" :endpoint="endpoint" :multiple="multiple" />
    </div>
</template> 

<script>
import SelectInput from '@/components/searchComponents/SelectInput.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
    props: {endpoint:String, name:String, data:Array, id:String, multiple:{default:true}},
    components: {
        SelectInput
    },
    mixins:[selectOptionsStorage],
    data() {
        return {
            select: {},
            selectedTags: {}
        }
    },
    created() {
        var t = this
        this.$on('selectInputAddTag',(tag) => {
            if (!this.multiple) {
                t.removeAlTagsFromKey(this.id)
            }
            t.addTag(this.id,tag)
            this.$parent.$parent.$emit('selectOptionsAddTag')
        } ) 
        this.$on('selectInputRemoveTag',(tag) => {
            t.removeTag(this.id,tag)
            this.$parent.$parent.$emit('selectOptionsRemoveTag')
        })
        this.$on('selectInputInputTag',(id) => {
            t.removeAlTagsFromKey(id)
            this.$parent.$parent.$emit('selectOptionsInputTag')
        }) 
        var selectedTags = this.getTagsFromKey(this.id)
        if (selectedTags != false) {
            this.selectedTags = selectedTags
        } else {
            this.selectedTags = ''
        }
    }
}
</script>

<style lang="scss">

     @import '@/web/scss/form.scss';

    .inputClass {
        margin: 15px;
        width: 100%;
    }
</style>