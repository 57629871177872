import api from '@/services/api.js'
import barramento from '@/barramento'
var filters = {
    data() {
        return {
            items:[],
            selectOptions:"",
        }
    },
    methods: {
        async getOptions() {
            var t = this
            await api.get("selectOptions").then(response => (
                t.items = response.data.data
            )).finally(function(){barramento.$emit('selectOptionsLoaded',t.items)})
        }
    }
  }

  export default filters