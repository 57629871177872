<template>
    <FilterComp info="false" id="taxonId">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data" :endpoint="endpointWithFilters">
            </slot>
        </template>
    </FilterComp>
</template>
<script>
import FilterComp from '@/components/searchComponents/FilterComponent.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
    components:{FilterComp},
    mixins:[selectOptionsStorage],
    data() {
        return {
            selectedValue: "",
            endpoint: '/searchTaxon?',
            endpointWithFilters: '',
        }
    },
    created() {
        this.getCheckedValue()
        this.$on('selectOptionsAddTag', ()=> {
            this.getCheckedValue()
        })
        this.$on('selectOptionsInputTag', ()=> {
            this.getCheckedValue()
        })
    },
    methods:{
        getCheckedValue() {
            var idLevel = this.getTagsFromKey('idLevel');
            var criteria = this.getTagsFromKey('criteria');
            var filters = '';
            if (idLevel != false) {
                idLevel = {...idLevel}
                filters += '&idLevel='+idLevel[0].id
            }
            if (criteria != false) {
                criteria = {...criteria}
                filters += '&criteria='+criteria[0].id
            }
            this.endpointWithFilters = this.endpoint + filters
        }
    }
}
</script>
