<template>
    <div class="mx-2">
        <Button name="Limpar filtros" type="fill" action="buttonLimparFiltrosClick" @buttonLimparFiltrosClick="buttonLimparFiltrosClick" :toggledButton="false"/>
    </div>
</template>
<script>
import Button from '@/components/forms/Button.vue'
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'
import barramento from '@/barramento'

export default {
    components:{Button},
    mixins:[toggleFilterStorage],
    methods:{
        buttonLimparFiltrosClick() {
            barramento.$emit("buttonLimparFiltrosClear")
        }
    }
}
</script>
