<template>
    <div class="mx-2">
        <Button name="Buscar" type="fill" action="buttonSearchClick" @buttonSearchClick.once="(e) =>{buttonSearchClick(true,e)}" :toggledButton="false"/>
    </div>
</template>
<script>
import Button from '@/components/forms/Button.vue'
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'
import barramento from '@/barramento'

export default {
    components:{Button},
    mixins:[toggleFilterStorage],
    methods:{
        buttonSearchClick(isAdvanced,e) {
            barramento.$emit("searchClick",isAdvanced)
            e.preventDefault()
        }
    }
}
</script>
