<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>
    <div v-else>
        <div class="d-flex justify-content-center">
            <table class="table-ficha table-pesq">
                <tr>
                    <th :class="'th-'+color">Tema</th>
                    <th></th>
                    <th :class="'th-'+color">Situação</th>
                    <th></th>
                    <th :class="'th-'+color">Referência Bibliográfica</th>
                </tr>
                <tr v-for="(item, index) in items.table" :key="index">
                    <td>{{item.theme}}</td>
                    <td></td>  
                    <td>{{item.situation}}</td>
                    <td></td>                    
                    <td v-if="item.refBibs.length > 0">{{item.refBibs[0].label}}</td>   
                    <td v-else>--</td>                 
                </tr>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    components:{
        LoadingAjax
    },
    props: ['fichaId','color'],
    data(){
        return {
            items: [],
            loading: true
        }
    },
    created(){
       
        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-pesq" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=research'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    }
}
</script>

<style>

</style>