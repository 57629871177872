<template>
  <div>
    <!--
      <b-modal
      no-close-on-backdrop
      no-close-on-esc
      id="modal_ficha"
      size="lg"
      :hide-header="true"
      hide-footer
    >
    -->
    <b-modal
      id="modal_ficha"
      size="lg"
      :hide-header="true"
      hide-footer
    >
    <div class="row">
        <div class="col-12 text-right close-button px-5 py-3">
            <button size="lg" @click="close()" title="Fechar janela"><i class="text-danger fa fa-times fa-lg"></i></button>
        </div>
    </div>
    <div class="row">
      <div class="col-12 px-3 h-100">
        <div class="ficha-header">
            <FichaHeader :fichaId="fichaId"></FichaHeader>
        </div>
        <div v-if="loadPage">
            <div class="ficha-classTax collapse-margin">
            <Collapse
                name="Classificação Taxonômica"
                id="classTax"
                :color="color"
            >
                <FichaTaxonomia :fichaId="fichaId" />
            </Collapse>
            </div>
            <div class="ficha-distr collapse-margin">
            <Collapse name="Distribuição" id="distr" :color="color">
                <FichaDistribuicao :fichaId="fichaId" />
            </Collapse>
            </div>
            <div class="ficha-hist collapse-margin">
            <Collapse name="História Natural" id="hist" :color="color">
                <FichaHistoria :fichaId="fichaId" />
            </Collapse>
            </div>
            <div class="ficha-pop collapse-margin">
            <Collapse name="População" id="pop" :color="color">
                <FichaPopulacao :fichaId="fichaId" />
            </Collapse>
            </div>
            <div class="ficha-ameaca collapse-margin">
            <Collapse name="Ameaças" id="ameaca" :color="color">
                <FichaAmeaca :fichaId="fichaId" />
            </Collapse>
            </div>
            <div class="ficha-uso collapse-margin">
            <Collapse name="Usos" id="uso" :color="color">
                <FichaUso :fichaId="fichaId" />
            </Collapse>
            </div>
            <div class="ficha-conserv collapse-margin">
            <Collapse name="Conservação" id="conserv" :color="color">
                <FichaConservacao :fichaId="fichaId" :color="color" />
            </Collapse>
            </div>
            <div class="ficha-pesq collapse-margin">
            <Collapse name="Pesquisas" id="pesq" :color="color">
                <FichaPesquisa :fichaId="fichaId" :color="color" />
            </Collapse>
            </div>
            <div class="ficha-pesq collapse-margin">
            <Collapse name="Avaliadores E Validadores" id="avaliadores" :color="color">
                <FichaAvaliadoresValidadores :fichaId="fichaId" :color="color" />
            </Collapse>
            </div>
            <div class="ficha-ref collapse-margin">
            <Collapse name="Referências Bibliográficas" id="ref" :color="color">
                <FichaRefBibliograficas :fichaId="fichaId" />
            </Collapse>
            </div>

            <div class="text-center mb-3 text-bold">Para mais informações, acesse a ficha completa em PDF</div>

            <!--
            <div class="container">
              <div class="row justify-content-center">
                <div class="textCit my-4 col-7 pt-5 align-self-center">
                  <span class="citar-bold">Como citar:</span>
                  <loading-ajax  v-if="loadingHowToCite"></loading-ajax>
                  <p v-else v-html="comoCitar"></p>
                </div>
              </div>
            </div>
          -->


        </div>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import barramento from "@/barramento";
import Collapse from "@/components/fichaComponents/ButtonCollapse.vue";
import FichaHeader from "@/components/fichaComponents/FichaHeader.vue";
import FichaTaxonomia from "@/components/fichaComponents/FichaTaxonomia.vue";
import FichaDistribuicao from "@/components/fichaComponents/FichaDistribuicao.vue";
import FichaHistoria from "@/components/fichaComponents/FichaHistoria.vue";
import FichaPopulacao from "@/components/fichaComponents/FichaPopulacao.vue";
import FichaAmeaca from "@/components/fichaComponents/FichaAmeaca.vue";
import FichaUso from "@/components/fichaComponents/FichaUso.vue";
import FichaConservacao from "@/components/fichaComponents/FichaConservacao.vue";
import FichaPesquisa from "@/components/fichaComponents/FichaPesquisa.vue";
import FichaAvaliadoresValidadores from "@/components/fichaComponents/FichaAvaliadoresValidadores.vue";
import FichaRefBibliograficas from "@/components/fichaComponents/FichaRefBibliograficas.vue";
//import api from '@/services/api.js'

export default {
  components: {
    Collapse,
    FichaTaxonomia,
    FichaDistribuicao,
    FichaHistoria,
    FichaPopulacao,
    FichaAmeaca,
    FichaUso,
    FichaConservacao,
    FichaPesquisa,
    FichaAvaliadoresValidadores,
    FichaRefBibliograficas,
    FichaHeader,
},
  data() {
    return {
      loadPage: false,
      sectionOpen: "",
      fichaId: "",
      color: "",
      items: {},
      comoCitar: '',
      loadingHowToCite:true,
    };
  },
  async created() {
    barramento.$on("color", (final) => {
        this.color = final;
        this.loadPage = true;
    });
    barramento.$on("openModalFicha", (id) => {
      this.loadPage = false;
      this.$bvModal.show("modal_ficha");
      this.fichaId = id;
    });
    barramento.$on("closeModalFicha", () => {
      this.close()
    });

    /*
    var t = this


    await api.get('/totalNumbers').then(response => {
        let data = response.data.data
        t.dataTotalNumbers = data
        t.comoCitar = data[4]['howToCite']
        barramento.$emit('homeTotalNumbersApiLoaded',data)
    }).finally(()=>{this.loadingHowToCite=false})
    */

  },
  methods: {
    close() {
      this.$bvModal.hide("modal_ficha");
    },

  },
};
</script>

<style lang="scss">
@import "@/web/scss/ficha.scss";

.table-ficha {
  width: 100%;

  .LC {
      background-color: #60C658;
  }
  .CR {
      background-color: #D81E06;
  }
  .EN {
      background-color: #FC7F40;
  }
  .VU {
      background-color: #F7E611;
      color: black !important;
  }
  .DD {
      background-color: #D1D1C6;
      color: black !important;

  }
  .RE {
      background-color: #9B4F96;
  }
  .EX {
      background-color: #000000;
  }
  .RE {
      background-color: #542244;
  }
  .NT {
      background-color: #CCE225;
      color: black !important;
  }
  .NA {
      background-color: #C1B5A5;
      color: black !important;
  }

  th,
  td {
    padding: 15px;
    text-align: center;
  }
  th {
    font-weight: bold;
    color: #505050;
    height: 40px;
    width: 300px;
  }
  td {
    background-color: #ebebeb;
    color: #505050;
    height: 40px;
    width: 300px;
    font-size: 14px;
    border-bottom: 1px solid #ffffff;
  }
  td:nth-child(2n),
  th:nth-child(2n) {
    background-color: transparent;
    width: 20px;
  }
}
.table-pesq {
  td:nth-child(1),
  td:nth-child(3),
  th:nth-child(1),
  th:nth-child(3) {
    width: 140px !important;
  }
}
.refBib {
  margin-bottom: 15px;
  width: 100%;
  font-weight: lighter;
}
.presenca {
  width: 300px;
}
</style>
