<template>
  <div>
    <label class="selectLabel" for="" v-if="value != ''">{{ name }}</label>
    <input
      type="text"      
      ref="fieldRef"
      :placeholder="placeholder"
      :value="value"
      :name="name"
      :id="id"
    />
  </div>
</template>

<script>

import selectOptionsStorage from "@/selectOptionsStorage_mixins.js";
import barramento from "@/barramento";

export default {
  props: {    
    name: String,
    id: String,
    placeholder: String,
  },
  mixins: [selectOptionsStorage],
  components: {},
  data() {
    return {
      value: "",
      storageKey: this.id,
    };
  },
  created() {
    if (this.getTagsFromKey(this.storageKey) != false) {
      this.value = this.getTagsFromKey(this.storageKey);
    }
    barramento.$on("openModalR", () => {
      if (this.getTagsFromKey(this.storageKey) != false) {
        this.value = this.getTagsFromKey(this.storageKey);
      }
    });
    barramento.$on("buttonLimparFiltrosClear", () => {
      this.value = "";
    });
    barramento.$on("searchClick", (isAdvanced) => {
      this.searchAction(isAdvanced);
    });
  },
  methods: {
    getValue(e) {
      if (e.currentTarget.value.length == 0) {
        this.removeAlTagsFromKey(this.storageKey);
      }
    },
    searchAction(isAdvanced = false, e = undefined) {
      if (this.$refs.fieldRef != undefined) {
        if (
          this.checkIfTagExists("filterFromGraphic", "true") ||
          isAdvanced == false
        ) {
          barramento.$emit("buttonLimparFiltrosClear");
        }
        var query = "";

        if (this.$refs.fieldRef) {
          query = this.$refs.fieldRef.value;
        }

        this.removeAlTagsFromKey(this.storageKey);
        if (query.length > 0) {
          this.addTag(this.storageKey, query);
        }
        barramento.$emit("openModalR");
        if (e != undefined) {
          e.preventDefault();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputClass input {
  width: 100%;
  height: 67px;
  border: none;
  border-bottom: 2px solid black;
  font-weight: bold;
  padding-top: 30px;
}
.inputClass .selectLabel {
  position: absolute;
  margin-top: 12px;
  font-size: 13px;
}

@media only screen and (min-width: 769px) {

}
@media only screen and (max-width: 768px) {

}

</style>