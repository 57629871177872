<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>
    <div v-else>
      <div class="text-bold">Histórico das Avaliações</div>
      <div class="d-flex justify-content-center" style="max-height: 300px; overflow-y: auto;">
            <table class="table-ficha">
                <tr>
                    <th :class="'th-'+color">Tipo</th>
                    <th :class="'th-'+color">Ano</th>
                    <th :class="'th-'+color">Abrangência </th>
                    <th :class="'th-'+color">Categoria </th>
                    <th :class="'th-'+color">Critério</th>
                    <th :class="'th-'+color">Referência Bibliográfica</th>
                </tr>
                <tr v-for="(item, index) in items.historyTable" :key="index">
                    <td><span v-html="item.type"></span></td>
                    <td><span v-html="item.year"></span></td>
                    <td><span v-html="item.coverage"></span></td>
                    <td><span v-html="item.category"></span></td>
                    <td><span v-html="item.criteria"></span></td>

                    <td v-if="item.refBibs.length > 0">
                        <span v-for="(item2, index2) in item.refBibs" :key="index2">
                            <span v-html="item2.label"></span><br>
                        </span>
                    </td>
                    <td v-else>--</td>
                </tr>
            </table>
        </div>

        <div class="session-gap"></div>
        <div class="info t-14 grey-text">Presença em lista nacional oficial de espécies ameaçadas de extinção:&nbsp;<span class="text-bold" v-html="items.officialListPresence"></span></div>

      <div class="session-gap"></div>
        <div class="text-bold">Presença em Áreas protegidas (UC/TI)</div>
        <div class="d-flex justify-content-center">
            <table class="table-ficha">
                <tr>
                    <th :class="'th-'+color">UC</th>
                    <th></th>
                    <th :class="'th-'+color">Referência Bibliográfica</th>
                </tr>
              <template v-for="(ucs, sphere) in ucsBySphere">
                <tr :key="'tr'+sphere">
                  <td colspan="3" class="text-bold text-left">{{sphere}}</td>
                </tr>
                <tr v-for="(uc,index) in ucs" :key="sphere+index">
                  <td>{{uc.name}}</td>
                  <td>&nbsp;</td>
                  <td v-if="uc.refBibs.length > 0">
                      <span v-html="uc.refBibs[0].label"></span>
                  </td>
                  <td v-else>&nbsp;</td>
                </tr>
              </template>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    components:{
        LoadingAjax
    },
    props: ['fichaId','color'],
    data(){
        return {
            items: [],
            loading: true,
            sphere:''
        }
    },
    created(){

        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-conserv" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=conservation'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    },computed:{
    ucsBySphere(){
      return this.items.UCPresenceTable.reduce((acc, uc) => {
        (acc[uc.sphere] = acc[uc.sphere] || []).push(uc)
        return acc
      }, {})
    }
  }
}
</script>

<style>
    .text-bold {
      font-weight: bold;
    }
    .th-LC {
        background-color: #60C65835 !important;
    }
    .th-CR {
        background-color: #D81E0635;
    }
    .th-EN {
        background-color: #FC7F4035;
    }
    .th-VU {
        background-color: #F7E61135;
        color: black !important;
    }
    .th-DD {
        background-color: #D1D1C635;
        color: black !important;

    }
    .th-RE {
        background-color: #9B4F9635;
    }
    .th-EX {
        background-color: #00000035;
    }
    .th-RE {
        background-color: #54224435;
    }
    .th-NT {
        background-color: #CCE22535;
        color: black !important;
    }
    .th-NA {
        background-color: #C1B5A535;
        color: black !important;
    }
</style>
