<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>
    <div v-else>
        <div class="refBib t-14" v-for="(item) in items.table.refsTaxon" :key="item">
            <div v-html="item"></div>
        </div>
      <template v-if="items.table.refsOccurrences.length > 0">
        <h4>Referência dos Registros</h4>
        <div class="refBib t-14" v-for="(item) in items.table.refsOccurrences" :key="item">
            <div v-html="item"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    components:{
        LoadingAjax
    },
    props: ['fichaId'],
    data(){
        return {
            items: [],
            loading: true
        }
    },
    created(){

        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-ref" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=bibliographicReferences'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    }
}
</script>

<style>

</style>
