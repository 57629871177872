<template>
  <div class="custom-control custom-switch">
    <input type="checkbox" :checked="value" @change="toggle" class="custom-control-input customSwitches" :id="id">
    <label class="custom-control-label" :for="id">{{name}}</label>
  </div>
</template>

<script>
// import barramento from '@/barramento'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'
export default {
    props: [ 'name', 'id' ,'filterId' ],
    mixins:[selectOptionsStorage,toggleFilterStorage],
    data() {
        return {
            value: false
        }
    },
    created() {
        if (this.getExtraFilters(this.filterId,this.id) == 'S') {
            this.value = true
        } else {
            this.value = false
        }
    },
    // destroyed(){
    //     this.removeAlTagsFromKey(this.id)
    // },
    methods: {
        toggle() {
            if (this.value == false) {
                this.value = true
            } else {
                this.value = false
            }

            this.$parent.$parent.$emit("switchButtonToggle")
        }
    }

}
</script>

<style>
    .custom-switch {
        margin-right: 15px;
        padding-top: 25px;
        margin-bottom: .5rem;

    }
    .custom-control-label {
        /* font-size: 12px;  */
        font-weight: bold;
    }
    .custom-control-label::before {
        border: #000 solid 2px !important;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        color: #000  !important;
        border-color: #BEE45B  !important;
        background-color: #BEE45B  !important;
    }
    .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 3px)  !important;
        left: calc(-2.25rem + 3px)  !important;
        width: calc(1rem - 6px)  !important;
        height: calc(1rem - 6px)  !important;
        background-color: #000 !important;
        border-radius: 0.5rem  !important;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
</style>