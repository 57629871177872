var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 my-2 px-0 tokenfield"},[_c('v-select',{attrs:{"label":"name","filterable":false,"options":_vm.options,"placeholder":_vm.name},on:{"search":_vm.onSearch,"option:selecting":_vm.tagAdded,"input":_vm.tagRemoved},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var path = ref.path;
var tree = ref.tree;
return [(path != undefined)?_c('span',{attrs:{"title":path}},[_vm._v(_vm._s(name))]):_vm._e(),(tree != undefined)?_c('span',{attrs:{"title":tree}},[_vm._v(_vm._s(name))]):_c('span',[_vm._v(_vm._s(name))])]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var loading = ref.loading;
return [(!loading && _vm.countCharForSearch!=0)?[_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Digite "+_vm._s(_vm.countCharForSearch)+" ou mais caracteres.")])]:_vm._e(),(!loading && _vm.countCharForSearch==0)?[_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" Nenhum resultado para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")])]:_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }