<template>
    <FilterComp info="false" :id="id">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data" :showFilter="showFilter">
            </slot>
        </template>
    </FilterComp>
</template>
<script>
import FilterComp from '@/components/searchComponents/FilterComponent.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
    props:['id'],
    components:{FilterComp},
    mixins:[selectOptionsStorage],
    data() {
        return {
            filterName:'pex',
            showFilter:false,
            activeFilterOnThisCategory: 'Criticamente em Perigo (CR)'
        }
    },
    created() {
        this.setPexFilter()
        this.$on('selectOptionsAddTag', ()=> {
            this.setPexFilter()
        })
        this.$on('selectOptionsRemoveTag', ()=> {
            this.setPexFilter()
        })
        this.$on('switchButtonToggle', () =>  {
            this.setPexFilter()
        })
    },
    methods:{
        setPexFilter() {
            this.$nextTick(function() { // lazy
                if (this.$scopedSlots.filter()[0].context.$refs[this.id] != undefined ) {
                    var selectOptions = this.$scopedSlots.filter()[0].context.$refs[this.id].$children[0].selected
                    var selectOptionsLength = 0
                    if (selectOptions != undefined) {
                        selectOptionsLength = selectOptions.length
                    }
                    var switchButtonValue = this.$scopedSlots.filter()[0].context.$refs[this.filterName]
                    if (switchButtonValue != undefined) {
                        switchButtonValue = this.$scopedSlots.filter()[0].context.$refs[this.filterName].value
                    }
                    //IF there is at least one item selected in the filter, then set filter
                    var checkIfExistsCategory = false
                    if (selectOptionsLength > 0) {
                        selectOptions.forEach(item  => {
                            if (item.name.indexOf(this.activeFilterOnThisCategory) > -1) {
                                checkIfExistsCategory = true
                            }
                        })
                        var checkFilter = this.checkIfTagExists(this.id,'extraFilters') 
                        if (checkFilter == true) {
                            this.removeTag(this.id,'extraFilters')
                        }

                        var filter = {}
                        filter[this.filterName] = new Array()
                        if (switchButtonValue) {
                            filter[this.filterName] = 'S'
                        } else { 
                            filter[this.filterName] = 'N'
                        } 

                        this.addTag(this.id,{'extraFilters': filter})  
                    } else {
                        this.checkIfExistisCRCategory = false
                    } 
                    if (checkIfExistsCategory) {
                        this.showFilter = true
                    } else {
                        this.removeTag(this.id,'extraFilters')
                        this.showFilter = false
                    }
                }
            });
        }
    }
}
</script>
