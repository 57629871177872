<template>
    <Button 
        :name="name" 
        :id="id" 
        :title="title"
        @filterButtonClicked="clicked" />
</template>
<script>
import Button from '@/components/searchComponents/filterButton.vue'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
export default {
    components:{Button},
    props:['name','id','value','title'],
    mixins:[selectOptionsStorage],
    methods: {
        clicked(actived) {
            if (actived) {
                this.addTag(this.id,this.value)
            } else {
                this.removeAlTagsFromKey(this.id)
            }
        }
    }
}
</script>
