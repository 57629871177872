<template>
  <div>
    <label class="selectLabel" for="" v-if="value != 0">{{name}}</label>
    <v-select
      :multiple="multiple"
      label="name"
      v-model="selected"
      :filterable="true"
      :options="options"
      :placeholder="name"
      @option:selecting="tagAdded"
      @option:deselected="tagRemoved"
      @input="tagInputed"
    >
      <template #no-options="{ search }">
      <em style="opacity: 0.5">
          Nenhum resultado para <em>{{ search }}</em>.
      </em>
      </template>
    </v-select> 
  </div>
</template>

<script>
import vSelect from "vue-select";
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'

export default {
  props: {endpoint:String, name:String, data:Array, id:String, multiple:{default:true}},
  mixins:[selectOptionsStorage],
  components: {
    vSelect,
  },
  data() {
    return {
      value:0,
      options: [],
      selected: '',
      vSelect
    }
  },
  created() {
      this.options = this.data
      let selected = this.getDataWithoutExtraFilters(this.id)
      if (selected != false) {
        selected = [...selected]
        if (!this.multiple) {
          this.selected = selected[0]
        } else {
          this.selected = selected
        }
      }
  },
  methods:{
    tagAdded (tag) {
      this.$parent.$emit('selectInputAddTag',tag)

    },
    tagRemoved(tag) {
        this.$parent.$emit('selectInputRemoveTag',tag)
    },
    tagInputed(tag){
      if (!tag || (tag != null && tag.length == 0)) {
        this.$parent.$emit('selectInputInputTag',this.id) 
      }
    }
  }
}
</script>

<style lang="scss">
  .inputClass select {
    width: 100%;
    height: 67px;
    border: none;
    border-bottom: 2px solid black;
    font-weight: bold;
    padding-top: 30px;
  }
  .inputClass .selectLabel {
    position: absolute;
    margin-top: 12px;
    font-size: 13px;
  }
  .vs__dropdown-toggle {
    border: 0 solid rgba(60, 60, 60, 0.26);
    border-bottom: 2px solid #282828;
    border-radius: 0px;
  }
  .vs__dropdown-menu {
    background-color: #bee45b;
    color: #282828;
    font-weight: bold;
  }
  .vs__dropdown-menu :hover{
    background-color: #e2f5a1;
    color: #282828;
    font-weight: bold;
  }
  @media only screen and (min-width: 769px) {
    .vs__selected {
      background-color: #fff;
      margin: 2px .6rem 3px 0;
      padding: 2px 10px 2px 10px;
      border-radius: 20px;
      border: solid 2px #282828;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.14;
      color: #282828;
    }
  }
  @media only screen and (max-width: 768px) {
    .vs__selected {
      background-color: #fff;
      margin: 2px .6rem 3px 0;
      padding: 2px 10px 2px 10px;
      border-radius: 20px;
      border: solid 2px #282828;
      font-size: 0.65rem;
      font-weight: bold;
      line-height: 1.14;
      color: #282828;
    }
  }

  .inputClass input {
    font-size: 1rem;
    font-weight: bold;
    line-height: 0.89;
    color: #282828;
  }
</style>