<template> 
    <div class="buttonCss">
        <Button :name="name" type="fill" @onClick="toggle" action="onClick" :active="active"/>
    </div>
</template>

<script>
import Button from '@/components/forms/Button.vue'
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'
import barramento from '@/barramento'

export default {
    props: ['name', 'id'],
    components: { 
        Button,
    },
    mixins:[toggleFilterStorage],
    data() {
        return {
            active: false,
            storageKey: 'activeFilterButton'
        }
    },
    created() {
        barramento.$on("buttonLimparFiltrosClear", ()=>{
            this.active = false
        })
        if (this.checkIfExists(this.storageKey,this.id) === true) {
            this.active = true
        } else {
            this.active = false
        }
    },
    methods: {
        toggle() {
            this.toggleItem(this.storageKey,this.id)
            if (this.checkIfExists(this.storageKey,this.id) === true) {
                this.active = true
            } else {
                this.active = false
            }
            this.$emit('filterButtonClicked',this.active)
        }
    }
}
</script>

<style>
    .buttonCss {
        margin-left: 15px;
        margin-right: 15px;
    }

@media only screen and (max-width: 1025px) {
    .buttonCss {
        margin-left: 3px;
        margin-right: 3px;
    }
}
</style>